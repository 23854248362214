import { Controller } from "stimulus"
import RecipientStateChartUtils from "../utils/recipient-state-chart-utils"

export default class extends Controller {
  static targets = ["chart"]

  connect() {
    const mode = this.element.dataset.recipientStatesGraphMode
    const data = JSON.parse(this.element.dataset.recipientStatesGraphRecipientStats)
    this.chartInstance = new RecipientStateChartUtils(this.chartTarget, data, mode).build()
  }
}
