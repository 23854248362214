import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.askPushToken()
  }

  askPushToken() {
    const mobileInterface = MobileInterface.get()
    if (mobileInterface && !mobileInterface.deviceTokenAlreadyExist()) {
      mobileInterface.askPushToken()
    }
  }
}
