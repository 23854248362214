import Chart from "chart.js/auto"

export default class RecipientStateChartUtils {
  constructor(target, data, type = 'bar') {
    this.target = target
    this.data = data
    this.type = type
  }

  build() {
    const categoriesColors = {
      sleep: 'rgba(104, 195, 248, 1)',
      appetite: 'rgba(255, 158, 25, 1)',
      hydratation: 'rgba(10, 43, 233, 0.7)',
      mood: 'rgba(231, 76, 60, 1)',
      coherence: 'rgba(196, 129, 255, 1)',
      serenity: 'rgba(184, 233, 134, 1)',
      autonomy: 'rgba(255, 218, 70, 1)',
    }

    const categoriesLabels = {
      sleep: "Sleep",
      appetite: "Appetite",
      hydratation: "Hydration",
      mood: "Mood",
      coherence: "Coherence",
      serenity: "Serenity",
      autonomy: "Autonomy",
    }

    const lastDay = new Date(this.data.lastDay)
    const labels = Array.from({ length: this.data.categories.sleep.length }, (_, i) => {
      const day = new Date(lastDay)
      day.setDate(lastDay.getDate() - (this.data.categories.sleep.length - 1 - i))
      return this.type === 'bar'
        ? day.toLocaleDateString('fr-FR', { weekday: 'long' })
        : day.toLocaleDateString('fr-FR', { day: '2-digit', month: 'short' })
    })

    const datasets = Object.keys(this.data.categories).map((category) => ({
      label: categoriesLabels[category],
      backgroundColor: this.type === 'bar' ? categoriesColors[category] : 'rgba(0, 0, 0, 0)',
      borderColor: categoriesColors[category],
      data: this.data.categories[category].map((value) => (value === null ? (this.type === 'line' ? null : 0) : value)),
      spanGaps: this.type === 'line',
      pointBackgroundColor: categoriesColors[category],
    }))

    return new Chart(this.target, {
      type: this.type,
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
            min: 0,
            max: 3,
            ticks: {
              stepSize: 1,
              callback: function (value) {
                if (value === 0) return "Very Bad"
                if (value === 1) return "Bad"
                if (value === 2) return "Average"
                if (value === 3) return "Good"
                return ""
              },
            },
          },
          x: {
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                const value = context.raw
                let state = ""
                if (value === 0) state = "Very Bad"
                if (value === 1) state = "Bad"
                if (value === 2) state = "Average"
                if (value === 3) state = "Good"
                return `${context.dataset.label}: ${state}`
              },
            },
          },
        },
      },
    })
  }
}
