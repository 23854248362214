import { Controller } from "stimulus"
import Chart from "chart.js/auto"

export default class extends Controller {
  static targets = ["chart"]

  connect() {
    const type = this.element.dataset.chartType
    const data = this.element.dataset.chartData

    if (!data) {
      console.error("Les données JSON sont manquantes pour le graphique de type", type)
      return
    }

    const parsedData = JSON.parse(data)
    this.chartInstance = new Chart(this.chartTarget, {
      type: type,
      data: this.prepareChartData(type, parsedData),
      options: this.getChartOptions(type)
    })
  }

  disconnect() {
    if (this.chartInstance) {
      this.chartInstance.destroy()
    }
  }

  prepareChartData(type, data) {
    if (type === "pie") {
      // Gestion du format pour un graphique en secteurs
      return {
        labels: Object.keys(data),
        datasets: [{
          data: Object.values(data),
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#7FB3D5']
        }]
      }
    } else if (type === "bar") {
      // Gestion du format pour un graphique en barres
      return {
        labels: Object.keys(data), // Les catégories
        datasets: [{
          label: 'Produits',
          data: Object.values(data), // Les valeurs pour chaque catégorie
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']
        }]
      }
    } else {
      // Gestion du format pour les graphiques en ligne et en aires
      return {
        labels: Object.keys(data[0].data), // Les dates
        datasets: data.map((series) => ({
          label: series.name,
          data: Object.values(series.data),
          fill: type === "area",
          backgroundColor: type === "area" ? this.getRandomColor(0.3) : this.getRandomColor(),
          borderColor: this.getRandomColor(),
          tension: type === "line" || type === "area" ? 0.2 : 0
        }))
      }
    }
  }

  getChartOptions(type) {
    return {
      maintainAspectRatio: false,
      responsive: true,
      scales: type !== "pie" ? {
        y: {
          beginAtZero: true
        }
      } : {}
    }
  }

  getRandomColor(alpha = 1) {
    const color = Math.floor(Math.random() * 16777215).toString(16)
    return `#${color}${Math.floor(alpha * 255).toString(16)}`
  }
}